const data = [
  { id: 1, link: "#", title: "Home" },
  { id: 2, link: "#about", title: "About" },
  { id: 3, link: "#Certificates", title: "Education" },
  { id: 4, link: "#services", title: "Services & Skills" },
  { id: 5, link: "#blog", title: "Blogs" },
  { id: 6, link: "#portfolio", title: "PortFolio" },
  { id: 7, link: "#contact", title: "Contact" },
];

export default data;
